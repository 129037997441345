import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import ResponseDisplay from '../components/ResponseDisplay';

const Result = () => {
  const location = useLocation();
  const { result } = location.state;
  const mbtiStr = useMemo(() =>
    (result.ie > 0.5 ? "e":"i") + (result.sn > 0.5 ? "n":"s") +(result.ft > 0.5 ? "t":"f") +(result.pj > 0.5 ? "j":"p"), [])
  console.log(result)

  return(
    <div style={{display: "flex", alignItems: "center"}}>
      <div style={{flex: "1", marginLeft: "25%", marginRight: "10%"}}>
        <h1>Result : {mbtiStr.toUpperCase()}</h1>

        <div>
          <div>
            <ResponseDisplay trueStr={"외향형"} falseStr={"내향형"} percentage={result.ie} />
            <ResponseDisplay trueStr={"직관형"} falseStr={"현실형"} percentage={result.sn} />
            <ResponseDisplay trueStr={"사고형"} falseStr={"감정형"} percentage={result.ft} />
            <ResponseDisplay trueStr={"계획형"} falseStr={"탐색형"} percentage={result.pj} />
          </div>
        </div>
      </div>

      <div style={{marginLeft: "auto"}}>
        <img src={"/resultImg/"+mbtiStr+".png"} alt={mbtiStr} style={{maxWidth: "50%", height: "auto"}}></img>
      </div>
    </div>
    
  );
};

export default Result;
