import React from 'react';

const ResponseDisplay = ({ trueStr, falseStr, percentage }) => {
  console.log(percentage)
  const percentageValue = Math.round(percentage * 100)
  const inversePercenteageValue = Math.round((1.0 - percentage) * 100)
  
  return (
    <div>
      <div style={{display: "flex", justifyContent: "left"}}>
        <div className='result-text'>
          <p>{percentageValue + "%"}</p>
          <p>{trueStr}</p>
        </div>

        <div className='result-text'>
          <p>{inversePercenteageValue + "%"}</p>
          <p>{falseStr}</p>
        </div>
      </div>

      <div className="progress-bar">
        <div
          className="progress-bar-inner"
          style={{ width: `${percentageValue}%` }}>
        </div>
      </div>

    </div>
  );
};

export default ResponseDisplay;
